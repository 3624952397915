<template>
  <div>
    <v-dialog v-model="show_dialog" width="600">
      <v-card>
        <v-card-title class="primary_2 text-h6 white--text py-2">
          Move to Disbursed
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog()">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-form ref="move_to_disbursed_form">
            <v-row class="ma-0">
              <v-col cols="3">
                <p class="field-title">
                  Name
                </p>
                <p class="field-content">
                  {{ this.customer_details.name }}
                </p>
              </v-col>
              <v-col cols="3">
                <p class="field-title">
                  Mobile
                </p>
                <p class="field-content">
                  {{ this.customer_details.mobile }}
                </p>
              </v-col>
              <v-col cols="6">
                <p class="field-title">
                  Email
                </p>
                <p class="field-content">
                  {{ this.customer_details.email }}
                </p>
              </v-col>
              <v-col cols="4">
                <p class="field-title">
                  NBFC
                </p>
                <p class="field-content">
                  {{ this.customer_details.alloted_nbfc }}
                </p>
              </v-col>
              <v-col cols="4">
                <p class="field-title">
                  Applied Loan Amount
                </p>
                <p class="field-content">
                  {{ this.customer_details.applied_amount }}
                </p>
              </v-col>
              <v-col cols="4">
                <p class="field-title">
                  Applied Tenure
                </p>
                <p class="field-content">
                  {{ this.customer_details.tenure }}
                </p>
              </v-col>
              <v-col cols="6" class="pb-0">
                <p class="field-title">
                  Disbursed Loan Amount
                </p>
                <p class="">
                  <v-text-field
                    v-model="disbursed_loan_amount"
                    outlined
                    placeholder="Enter Amount"
                    :rules="$rules.REQURIED_FILED"
                    type="number"
                    dense
                  >
                  </v-text-field>
                </p>
              </v-col>
              <v-col cols="6" class="pb-0">
                <p class="field-title">
                  Disbursed Tenure
                </p>
                <p class="">
                  <v-text-field
                    v-model="disbursed_tenure"
                    outlined
                    type="number"
                    placeholder="Enter Tenure"
                    dense
                    suffix="Months"
                  >
                  </v-text-field>
                </p>
              </v-col>
              <v-col cols="6" class="pt-0">
                <p class="field-title">
                  Disbursed Date
                </p>
                <p class="">
                  <v-menu
                    v-model="disbursed_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="disbursed_date"
                        v-on="on"
                        placeholder="Select Date"
                        dense
                        outlined
                        :rules="$rules.REQURIED_FILED"
                        readonly
                      >
                        <template slot="append">
                          <v-icon
                            v-if="disbursed_date"
                            @click="disbursed_date = null"
                            >mdi-close
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="disbursed_date">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="disbursed_date_menu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary"
                        text
                        @click="disbursed_date_menu = false"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end pb-4">
          <v-btn
            color="success"
            rounded
            class="mx-1 white--text px-4 font-weight-bold"
            @click="moveToDisbursed()"
            :loading="disburse_btn_loader"
            depressed
            :disabled="disburse_btn_loader"
          >
            <!-- <v-icon>mdi-cancel</v-icon> -->
            Move
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      disburse_btn_loader: false,
      customer_details: {},
      disbursed_loan_amount: null,
      disbursed_tenure: null,
      disbursed_date_menu: false,
      disbursed_date: null,
    };
  },
  computed: {},
  methods: {
    async openMovetoDisbursed(value) {
      this.show_dialog = true;
      this.customer_details = value;
    },
    closeDialog() {
      this.disburse_btn_loader = false;
      this.disbursed_loan_amount = "";
      this.disbursed_date = "";
      this.disbursed_tenure = "";
      this.show_dialog = false;
    },
    moveToDisbursed() {
      if (this.$refs.move_to_disbursed_form.validate()) {
        const self = this;

        let form = {
          sanctioned_amount: self.disbursed_loan_amount,
          disbursal_date: self.disbursed_date,
        };
        if(self.disbursed_tenure){
          form.tenure= self.disbursed_tenure
        }
        self.disburse_btn_loader = true;
        self.loan_amount;

        const successHandler = () => {
          this.$emit("update");
          self.closeDialog();
        };
        const finallyHandler = () => {
          self.disburse_btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.DISBURSE_LEAD_BY_NBFC + `${this.customer_details.id}/`,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
  },
};
</script>
<style scoped></style>
